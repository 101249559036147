<template>
    <BContainer>
        <Header />
    </BContainer>
    <BContainer class="main-content">
        <slot></slot>
    </BContainer>
    <BContainer class="my-3">
        <Footer />
    </BContainer>
</template>
<script>
import Header from "@/components/globals/Header.vue";
import Footer from "@/components/globals/Footer.vue";
export default {
    components: {
        Header,
        Footer,
    },

    created() {
        if (!this.userData?.docId) {
            this.$router.push("/");
        }
    },
};
</script>
<style lang="scss">
.main-content {
    border-radius: 8px;
    box-shadow: 0px 20px 24px 0px rgba(153, 155, 168, 0.1);
    background: #fcfcfc;
    padding: 30px;
}
</style>
