<template>
    <BRow class="d-flex justify-content-center align-items-center login-page">
        <BCol class="col-xs-10 col-sm-8 col-md-7 col-lg-5 col-xl-4">
            <BCard class="settings__inner py-3">
                <BCardBody class="text-center">
                    <div
                        class="d-flex justify-content-center align-items-center"
                    >
                        <img class="h-50 w-50 mx-2" :src="logo" alt="" />
                    </div>
                    <!-- @submit.prevent="handleForgetPassword" -->
                    <form @submit.prevent="handleForgetPassword">
                        <BFormGroup class="my-2 inner__form">
                            <div
                                class="d-flex flex-column justify-content-start align-items-start"
                            >
                                <label
                                    class="text-uppercase inner__form-label mt-3 mb-2"
                                    >Email</label
                                >
                                <div class="position-relative w-100">
                                    <BFormInput
                                        required
                                        type="email"
                                        placeholder="Email ID"
                                        class="inner__form-input"
                                        v-model="email"
                                    ></BFormInput>
                                </div>
                            </div>
                        </BFormGroup>
                        <BButton
                            :disabled="this.loader"
                            class="w-100 inner__form-btn py-3"
                            type="submit"
                        >
                            {{
                                this.loader ? "Loading..." : "Reset Password"
                            }}</BButton
                        >
                    </form>
                    <div class="d-flex justify-content-end">
                        <router-link to="/login" class="text-decoration-none">
                            <label
                                class="text-uppercase inner__form-label mt-3 cursor-pointer"
                                >Login</label
                            >
                        </router-link>
                    </div>
                </BCardBody>
            </BCard></BCol
        >
    </BRow>
</template>

<script>
import logo from "@/assets/imgs/logo.png";
export default {
    data() {
        return {
            email: "",
            logo,
        };
    },
    methods: {
        handleForgetPassword() {
            this.userForgetPassword({
                email: this.email,

                onSuccess: () => {
                    this.email = "";
                    this.$router.push("/login");
                },
            });
        },
    },
};
</script>

<style></style>
