import { createStore } from "vuex";

import HonourUser from "./modules/HonourUser";
import Slides from "./modules/Slides";
export default createStore({
    modules: {
        HonourUser,
        Slides,
    },
});
