<template>
    <BContainer>
        <slot></slot>
    </BContainer>
</template>
<script>
export default {
    name: "Auth",

    created() {
        if (this.userData?.docId) {
            if (this.userData?.role === "customer") {
                this.$router.push(`/slides/${this.userData?.docId}`);
            } else {
                this.$router.push("/profile");
            }
        }
    },
};
</script>
