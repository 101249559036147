import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import Slides from "../views/Slides.vue";
import Screen from "../views/Screen.vue";
import Profile from "../views/Profile.vue";
import Auth from "../layout/Auth.vue";
import Main from "../layout/Main.vue";
import LoadScreen from "../views/LoadScreen.vue";
import Screens from "../layout/Screens.vue";
import ForgetPassword from "../views/ForgetPassword.vue";
const routes = [
    {
        path: "/:catchAll(.*)",
        redirect: "/",
    },
    {
        path: "/",
        name: "Login",
        meta: { layout: Auth },

        component: Login,
    },
    {
        path: "/load-screen",
        name: "Username",
        meta: { layout: Screens },
        component: LoadScreen,
    },
    {
        path: "/profile",
        name: "Profile",
        meta: { layout: Main },
        component: Profile,
    },
    {
        path: "/slides/:id",
        name: "Slides",
        meta: { layout: Main },

        component: Slides,
    },
    {
        path: "/screen/:userid",
        name: "screen",
        meta: { layout: Screens },
        component: Screen,
    },
    {
        path: "/forget-password",
        name: "forget-screen",
        meta: { layout: Screens },
        component: ForgetPassword,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
