import axios from "axios";
import firebase from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";

const state = {
    userData: JSON.parse(localStorage.getItem("userData")) || {},
    loader: false,
    usersProfile: [],
    usersScreen: [],
    userName: null,
    userUid: null,
};
const getters = {
    userData: (state) => state.userData,
    loader: (state) => state.loader,
    usersProfile: (state) => state.usersProfile,
    usersScreen: (state) => state.usersScreen,
    userName: (state) => state.userName,
    userUid: (state) => state.userUid,
};

const mutations = {
    setUser(state, payload) {
        state.userData = payload;
        localStorage.setItem("userData", JSON.stringify(payload));
    },
    setLoader(state, payload) {
        state.loader = payload;
    },
    setUsersProfile(state, payload) {
        state.usersProfile = payload;
    },
    setUserScreen(state, payload) {
        console.log("payload", payload);
        state.usersScreen = payload;
    },
    setUserName(state, payload) {
        console.log("payload: ", payload);
        state.userName = payload;
    },
    setUserUid(state, payload) {
        console.log("payload: ", payload);
        state.userUid = payload;
    },
};
const actions = {
    async UserLogin({ commit }, { email, password, onSuccess }) {
        console.log("email: ", email);
        try {
            commit("setLoader", true);

            let customerCheck = await firebase
                .firestore()
                .collection("users")
                .where("email", "==", email.toLowerCase())
                .get();

            console.log("customerCheck", customerCheck);

            let userData = {
                docId: customerCheck.docs[0].id,
                ...customerCheck.docs[0].data(),
            };
            console.log("userData: outside ", userData);

            if (userData) {
                await firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                    .then(async (data) => {
                        if (data.user.uid) {
                            onSuccess();
                            commit("setLoader", false);
                            commit("setUser", userData);
                        } else {
                            commit("setLoader", false);
                            alert("Invalid User!");
                        }
                    });
            }

            onSuccess();
        } catch (error) {
            alert(error);
            commit("setLoader", false);
            console.error(error);
        }
    },

    async checkEmail({ commit }, { email, onSuccess }) {
        try {
            let usernamesRef = await firebase.firestore().collection("users");
            const snapshot = await usernamesRef
                .where("email", "==", email)
                .get();
            if (snapshot.empty) {
                onSuccess(false);
            } else {
                onSuccess(true);
            }
        } catch (error) {
            alert(error);
        }
    },
    async checkUpdateEmail({ commit }, { createdBy, email, onSuccess }) {
        try {
            const usernamesRef = firebase.firestore().collection("users");

            const snapshot = await usernamesRef
                .where("email", "==", email)
                .where(
                    firebase.firestore.FieldPath.documentId(),
                    "!=",
                    createdBy
                )
                .get();
            if (snapshot.empty) {
                onSuccess(true);
            } else {
                onSuccess(false);
            }
        } catch (error) {
            alert(error);
        }
    },

    async callCreateUser({ commit }, { email, password }) {
        try {
            commit("setLoader", true);
            const { data } = await axios.post(
                "https://us-central1-club-honours-slides.cloudfunctions.net/createUser",
                {
                    email,
                    password,
                }
            );
            commit("setUserUid", data.data.uid);
            commit("setLoader", false);
            return data;
        } catch (error) {
            throw error;
            commit("setLoader", false);
        }
    },

    async AddProfileUser({ commit }, { payload, onSuccess }) {
        try {
            commit("setLoader", true);
            const uid = payload.uid;

            if (payload?.profileImage) {
                const imageFile = payload.profileImage;
                const fileName = imageFile.name;
                const fileExtension = fileName.slice(fileName.lastIndexOf("."));
                const fileNameWithExtension =
                    uuidv4() + fileExtension.toLowerCase();
                const storageRef = firebase
                    .storage()
                    .ref("profile_logo/" + fileNameWithExtension);

                const uploadTaskSnapshot = await storageRef.put(imageFile);
                const downloadURL =
                    await uploadTaskSnapshot.ref.getDownloadURL();

                const userDocRef = firebase
                    .firestore()
                    .collection("users")
                    .doc(uid);
                await userDocRef.set({
                    profileLogoUrl: downloadURL,
                    createdAt: firebase.firestore.Timestamp.now(),
                    organization: payload.organization,
                    email: payload.email,
                    createdBy: payload.createdBy,
                    role: "customer",
                });

                alert("Data added Successfully");
                onSuccess();
                commit("setLoader", false);
            } else {
                const userDocRef = firebase
                    .firestore()
                    .collection("users")
                    .doc(uid); // Use the uid as the document ID
                await userDocRef.set({
                    profileLogoUrl: "",
                    createdAt: firebase.firestore.Timestamp.now(),
                    role: "customer",
                    ...payload,
                });

                alert("Data added Successfully");
                onSuccess();
                commit("setLoader", false);
            }
        } catch (error) {
            alert(error);
            commit("setLoader", false);
            console.error(error);
        }
    },
    async updateProfileUser({ commit }, { payload, onSuccess }) {
        console.log(" payload", payload);
        try {
            commit("setLoader", true);
            const firestore = firebase.firestore();
            const usersCollection = firestore.collection("users");
            if (payload?.profileImage.name) {
                let userInfo = await usersCollection.doc(payload.id).get();
                if (userInfo.data()) {
                    if (userInfo.data()?.profileLogoUrl != "") {
                        await firebase
                            .storage()
                            .refFromURL(userInfo?.data()?.profileLogoUrl)
                            .delete();
                    }
                }
                const imageFile = payload.profileImage;
                const fileName = imageFile.name;
                const fileExtension = fileName.slice(fileName.lastIndexOf("."));
                const fileNameWithExtension =
                    uuidv4() + fileExtension.toLowerCase();
                const storageRef = firebase
                    .storage()
                    .ref("profile_logo/" + fileNameWithExtension);

                try {
                    const uploadTaskSnapshot = await storageRef.put(imageFile);
                    const downloadURL =
                        await uploadTaskSnapshot.ref.getDownloadURL();
                    await usersCollection.doc(payload.id).update({
                        profileLogoUrl: downloadURL,
                        // password: payload.password,
                        organization: payload.organization,
                        email: payload.email,
                        updatedAt: firebase.firestore.Timestamp.now(),
                    });
                    alert("profile updated Successfully");
                    onSuccess();
                    commit("setLoader", false);
                } catch (error) {
                    console.error("Error uploading image:", error);
                    commit("setLoader", false);
                }
            } else {
                if (payload.profileImage == "") {
                    let userInfo = await usersCollection.doc(payload.id).get();
                    if (userInfo.data()) {
                        if (userInfo.data()?.profileLogoUrl != "") {
                            await firebase
                                .storage()
                                .refFromURL(userInfo?.data()?.profileLogoUrl)
                                .delete();
                        }
                    }
                }

                await usersCollection.doc(payload.id).update({
                    updatedAt: firebase.firestore.Timestamp.now(),
                    profileLogoUrl: payload.profileImage,
                    // password: payload.password,
                    organization: payload.organization,
                    email: payload.email,
                });
                alert("profile updated Successfully");
                onSuccess();
                commit("setLoader", false);
            }
        } catch (error) {
            alert(error);
            commit("setLoader", false);

            console.error(error);
        }
    },

    async getProfileUser({ commit }, { createdBy }) {
        console.log("userID", createdBy);
        await firebase
            .firestore()
            .collection("users")
            .where("createdBy", "==", createdBy)
            .onSnapshot(async (query) => {
                let tempUser = [];
                for (let doc of query.docs) {
                    tempUser.push({ docId: doc.id, ...doc.data() });
                }
                commit("setUsersProfile", tempUser);
            });
    },

    async deleteProfileUser({ commit }, { id, imageUrl }) {
        try {
            await firebase.firestore().collection("users").doc(id).delete();
            await firebase.storage().refFromURL(imageUrl).delete();
            alert("Profile deleted successfully");
        } catch (error) {
            console.error("Error deleting slide:", error);
        }
    },

    async getScreenUser({ commit }, { userId }) {
        await firebase
            .firestore()
            .collection("slides")
            .where("userId", "==", userId)
            .orderBy("order", "asc")
            .onSnapshot(async (query) => {
                let tempUserScreen = [];
                for (let doc of query.docs) {
                    tempUserScreen.push({ id: doc.id, ...doc.data() });
                }
                commit("setUserScreen", tempUserScreen);
            });
    },
    async userForgetPassword({ commit }, { email, onSuccess = () => {} }) {
        try {
            commit("setLoader", true);
            var actionCodeSettings = {
                // url: "http://localhost:8080/login",
                url: window.location.origin,
                handleCodeInApp: false,
            };

            await firebase
                .auth()
                .sendPasswordResetEmail(email, actionCodeSettings);
            alert("Please Check your Email to Update Password!");
            commit("setLoader", false);

            onSuccess();
        } catch (error) {
            commit("setLoader", false);

            console.error("Error sending password reset email:", error);
        }
    },
    async logout({ commit, state }, { onSuccess }) {
        try {
            localStorage.clear();
            state.userData = null;
            onSuccess();
        } catch (error) {
            alert(error);

            console.error(error);
        }
    },

    async userNameLogin({ commit }, { username, onSuccess }) {
        console.log("username: ", username);

        await firebase
            .firestore()
            .collection("users")
            .where("username", "==", username)
            .onSnapshot(async (query) => {
                let tempUser = [];
                for (let doc of query.docs) {
                    tempUser.push({ id: doc.id, ...doc.data() });
                }
                console.log("setUserName", tempUser[0]);
                commit("setUserName", tempUser[0]);
                onSuccess();
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
