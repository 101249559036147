<template>
    <form @submit.prevent="formData.id ? updateData() : saveData()">
        <BRow>
            <BCol lg="6" class="mb-2">
                <div>
                    <div class="d-flex align-items-center mb-2">
                        <span class="input-label">Slide Name</span>
                    </div>
                    <div>
                        <BFormInput
                            v-model="formData.slideName"
                            type="text"
                            placeholder="Slide Name"
                            class="input-field"
                        ></BFormInput>
                    </div>
                </div>
            </BCol>
            <BCol lg="6" class="mb-2">
                <div>
                    <div class="d-flex align-items-center mb-2">
                        <span class="input-label">Slide Image</span>
                    </div>
                    <BRow class="justify-content-between">
                        <BCol lg="2" class="my-lg-0 my-1">
                            <div>
                                <img
                                    class="file-img"
                                    :src="slidePreview || AvatarSlider"
                                />
                            </div>
                        </BCol>
                        <BCol lg="6" class="my-lg-0 my-1">
                            <div
                                @click="openFileDialog"
                                class="input-file d-flex justify-content-center align-items-center cursor-pointer"
                            >
                                <div class="mx-2">
                                    <img :src="file" />
                                </div>
                                <div class="input-file__text">
                                    <span>Upload new slide</span>
                                </div>
                            </div>
                        </BCol>
                        <BCol lg="4" class="my-lg-0 my-1">
                            <div
                                class="delete-current text-center"
                                @click="deleteCurrentImage"
                            >
                                Delete current
                            </div>
                        </BCol>

                        <input
                            ref="fileInput"
                            type="file"
                            style="display: none"
                            @change="handleFileChange"
                        />
                    </BRow>
                </div>
            </BCol>
            <BCol lg="6" class="my-2">
                <div>
                    <div class="d-flex align-items-center mb-2">
                        <span class="input-label">Duration (Seconds)</span>
                        <span
                            class="px-2 cursor-pointer"
                            v-b-tooltip.hover
                            title="This will determine for how long this specific page is static on your display before moving onto next slide"
                            ><img :src="subtract"
                        /></span>
                    </div>
                    <div>
                        <BFormInput
                            v-model="formData.duration"
                            type="number"
                            placeholder="Duration"
                            class="input-field"
                        ></BFormInput>
                    </div>
                </div>
            </BCol>
            <BCol lg="6" class="my-2">
                <div>
                    <div class="d-flex align-items-center mb-2">
                        <span class="input-label">Order</span>
                        <span
                            class="px-2 cursor-pointer"
                            v-b-tooltip.hover
                            title="This will determine for how long this specific page is static on your display before moving onto next slide"
                            ><img :src="subtract"
                        /></span>
                    </div>
                    <div>
                        <BFormInput
                            v-model="formData.order"
                            type="text"
                            placeholder="Order"
                            class="input-field"
                        ></BFormInput>
                    </div>
                </div>
            </BCol>
        </BRow>
        <BRow class="my-2">
            <div class="d-flex justify-content-end">
                <BButton
                    class="slides__btn"
                    type="submit"
                    :disabled="this.addSlideloader"
                    v-if="!this.addSlideloader"
                >
                    {{ this?.formData.id ? "Update" : "Save" }}</BButton
                >
                <BButton class="slides__btn" v-else>...loading</BButton>
            </div>
        </BRow>
    </form>
</template>

<script>
import subtract from "../../assets/imgs/Subtract.png";
import file from "../../assets/imgs/file.png";
import AvatarSlider from "@/assets/imgs/AvatarSlider.png";

export default {
    props: {
        paramId: String,
        updateData: Object,
    },
    data() {
        return {
            fileInput: null,
            subtract,
            file,
            AvatarSlider,
            formData: {
                slideName: "",
                slideImage: "",
                duration: "",
                order: "",
                id: "",
            },
            slidePreview: "",
        };
    },
    methods: {
        openFileDialog() {
            this.$refs.fileInput.click();
        },

        saveData() {
            const dataToSave = {
                slideName: this.formData.slideName,
                slideImage: this.formData.slideImage,
                duration: Number(this.formData.duration),
                order: Number(this.formData.order),
                userId: this.paramId,
            };
            this.addNewSlide({
                payload: dataToSave,
                onSuccess: () => {
                    this.formData = {
                        slideName: "",
                        slideImage: "",
                        duration: "",
                        order: "",
                    };
                    this.slidePreview = null;
                },
            });
        },
        updateData() {
            const dataToUpdate = {
                slideName: this.formData.slideName,
                slideImage: this.formData.slideImage,
                duration: Number(this.formData.duration),
                order: Number(this.formData.order),
                id: this.formData.id,
            };
            console.log("dataToUpdate", dataToUpdate);
            this.updateSlide({
                payload: dataToUpdate,
                onSuccess: () => {
                    this.formData = {
                        slideName: "",
                        slideImage: "",
                        duration: "",
                        order: "",
                        id: "",
                    };
                    this.slidePreview = "";
                },
            });
        },

        handleFileChange(event) {
            const selectedFile = event.target.files[0];

            const allowedTypes = [
                "image/png",
                "image/jpeg",
                "image/jpg",
                "image/svg+xml",
            ];
            if (allowedTypes.includes(selectedFile?.type)) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.slidePreview = reader.result;
                    this.formData.slideImage = selectedFile;
                    this.$refs.fileInput.value = "";
                };
                reader.readAsDataURL(selectedFile);
            } else {
                alert("Invalid File Select Image");
            }
        },
        deleteCurrentImage() {
            this.slidePreview = null;
            this.formData.slideImage = "";
        },
    },
    watch: {
        updateData() {
            if (this.updateData) {
                if (this.updateData) {
                    this.formData.slideName = this.updateData.Image.name;
                    this.formData.slideImage = this.updateData.Image.url;
                    this.formData.duration = this.updateData.Duration;
                    this.formData.order = this.updateData.Order;
                    this.formData.id = this.updateData.id;
                    this.slidePreview = this.updateData.Image.url;
                }
            }
        },
    },
    mounted() {
        this.fileInput = this.$refs.fileInput;
    },
};
</script>

<style lang="scss" scoped></style>
