<template>
    <BRow>
        <BCard class="border-0 footerCard">
            <div class="d-flex justify-content-between align-items-center">
                <div
                    class="d-flex align-items-center cursor-pointer"
                    @click="handleLogout"
                >
                    <div class="footerCard__svgIcon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.897 14.1929L14.678 11.4119H4.29492V9.26446H14.678L11.897 6.48346L13.4218 4.96948L18.7905 10.3382L13.4218 15.7069L11.897 14.1929ZM17.1799 0.672607C18.3659 0.672607 19.3274 1.63407 19.3274 2.8201V7.83448L17.1799 5.687V2.8201H2.14749V17.8525H17.1799V14.9856L19.3274 12.8381V17.8525C19.3274 19.0385 18.3659 20 17.1799 20H2.14749C0.955633 20 0 19.0336 0 17.8525V2.8201C0 1.62824 0.955633 0.672607 2.14749 0.672607H17.1799Z"
                                fill="#7F85A2"
                            />
                            <mask
                                id="mask0_905_2806"
                                style="mask-type: luminance"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.897 14.1929L14.678 11.4119H4.29492V9.26446H14.678L11.897 6.48346L13.4218 4.96948L18.7905 10.3382L13.4218 15.7069L11.897 14.1929ZM17.1799 0.672607C18.3659 0.672607 19.3274 1.63407 19.3274 2.8201V7.83448L17.1799 5.687V2.8201H2.14749V17.8525H17.1799V14.9856L19.3274 12.8381V17.8525C19.3274 19.0385 18.3659 20 17.1799 20H2.14749C0.955633 20 0 19.0336 0 17.8525V2.8201C0 1.62824 0.955633 0.672607 2.14749 0.672607H17.1799Z"
                                    fill="white"
                                />
                            </mask>
                            <g mask="url(#mask0_905_2806)"></g>
                        </svg>
                    </div>
                    <p class="mb-0 footerCard__text mx-2">Log out</p>
                </div>
                <div>
                    <p class="mb-0 footerCard__text">
                        © {{ getCurrentYear() }}, All rights reserved
                    </p>
                </div>
            </div>
        </BCard>
    </BRow>
</template>

<script>
export default {
    methods: {
        handleLogout() {
            this.logout({
                onSuccess: () => {
                    this.$router.push("/");
                },
            });
        },
        getCurrentYear() {
            return new Date().getFullYear();
        },
    },
};
</script>

<style></style>
