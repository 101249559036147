<template>
    <BRow
        class="justify-content-between align-items-center pagination-bottom mt-5"
    >
        <BCol md="6" class="my-md-0 my-lg-0 my-2">
            <div class="pagination-number">
                {{
                    `Showing ${
                        startNumber == 0 ? 1 : startNumber
                    }-${endNumber} of ${items.length}`
                }}
            </div>
        </BCol>
        <BCol md="6" class="my-md-0 my-lg-0 my-2">
            <div class="d-flex justify-content-md-end">
                <div class="pagination-container">
                    <div class="pagination">
                        <div
                            class="pagination-newer"
                            :class="{ disabled: !showPreviousIcon }"
                            @click="handlePreviousPagination"
                        >
                            <i class="fas fa-chevron-left fx-2"></i>
                        </div>
                        <div class="d-flex">
                            <span
                                v-for="page in visiblePages"
                                :key="page"
                                :class="{
                                    'pagination-active': page === currentPage,
                                }"
                                class="pagination-inner"
                                @click="setActivePagination(page)"
                            >
                                {{ page }}
                            </span>
                            <span
                                v-if="currentPage < totalPages - 4"
                                class="pagination-inner"
                                >...</span
                            >
                        </div>
                        <div
                            class="pagination-older"
                            :class="{ disabled: !showNextIcon }"
                            @click="handleNextPagination"
                        >
                            <i class="fas fa-chevron-right fx-2"></i>
                        </div>
                    </div>
                </div>
            </div>
        </BCol>
    </BRow>
</template>

<script>
export default {
    props: {
        currentPage: {
            type: Number,
            required: true,
        },
        totalPages: {
            type: Number,
            required: true,
        },
        items: {
            type: Array,
        },
        startNumber: {
            type: Number,
        },
        endNumber: {
            type: Number,
        },
    },
    methods: {
        setActivePagination(page) {
            this.$emit("page-changed", page);
        },
        handlePreviousPagination() {
            if (this.currentPage > 1) {
                this.setActivePagination(this.currentPage - 1);
            }
        },
        handleNextPagination() {
            if (this.currentPage < this.totalPages) {
                this.setActivePagination(this.currentPage + 1);
            }
        },
    },
    computed: {
        visiblePages() {
            const maxVisiblePages = 5;
            const bufferPages = Math.floor(maxVisiblePages / 2);

            let startPage = Math.max(1, this.currentPage - bufferPages);
            let endPage = Math.min(
                this.totalPages,
                startPage + maxVisiblePages - 1
            );

            if (endPage - startPage + 1 < maxVisiblePages) {
                // Adjust the range if there are fewer than 5 pages
                startPage = Math.max(1, endPage - maxVisiblePages + 1);
            }

            const pages = [];
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
            return pages;
        },
        showPreviousIcon() {
            return this.currentPage > 1;
        },
        showNextIcon() {
            return this.currentPage < this.totalPages;
        },
    },
};
</script>
<style scoped>
.pagination-number {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #667085;
    cursor: pointer;
}

.pagination-bottom {
    background: #fcfcfc;
    padding: 10px 0;
}
.pagination p {
    position: relative;
    display: inline-block;
    color: #222222;
    text-decoration: none;
    font-size: 1rem;
    padding: 6px 14px 8px;
    margin-right: 1rem;
    cursor: pointer;
    user-select: none;
}
@media (max-width: 450px) {
    .pagination p {
        font-size: 0.9rem;
        padding: 6px 14px 8px;
        margin-right: 0.5rem;
    }
}

.pagination-active {
    color: #fff;
    margin-right: 6px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #5ac79c !important;
}

.pagination-active:before {
    transform: scale(1);
}

.pagination-active:before {
    transform: scale(1);
}
.pagination-newer {
    margin-right: 6px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #eaeaea;
}
.pagination-inner {
    cursor: pointer;
    margin-right: 6px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #eaeaea;
}
.pagination-older {
    margin-right: 6px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #eaeaea;
}
</style>
