<template>
    <BRow class="header px-auto px-lg-0">
        <div>
            <img :src="logo" class="header__img" />
        </div>
    </BRow>
</template>

<script>
import logo from "@/assets/imgs/creative.png";
export default {
    data() {
        return {
            logo,
        };
    },
};
</script>

<style></style>
