import firebase from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
const state = {
    addSlideloader: false,
    slidesData: [],
};
const getters = {
    addSlideloader: (state) => state.addSlideloader,
    slidesData: (state) => state.slidesData,
};
const mutations = {
    SET_LOADER(state, payload) {
        state.addSlideloader = payload;
    },
    SET_SLIDES_DATA(state, payload) {
        state.slidesData = payload;
    },
};
const actions = {
    //ADDING NEW SLIDE
    async addNewSlide({ commit }, { payload, onSuccess }) {
        console.log("payload: ", payload);
        commit("SET_LOADER", true);
        try {
            const imageFile = payload.slideImage;

            const fileName = imageFile.name;
            const fileExtension = fileName.slice(fileName.lastIndexOf("."));
            const fileNameWithExtension =
                uuidv4() + fileExtension.toLowerCase();
            const storageRef = firebase
                .storage()
                .ref("slide_images/" + fileNameWithExtension);

            const uploadTaskSnapshot = await storageRef.put(imageFile);
            const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

            await firebase.firestore().collection("slides").add({
                slideName: payload.slideName,
                slideImageUrl: downloadURL,
                duration: payload.duration,
                order: payload.order,
                createdAt: firebase.firestore.Timestamp.now(),
                userId: payload.userId,
            });

            alert("Data added successfully");
            onSuccess();
            commit("SET_LOADER", false);
        } catch (error) {
            alert(error);
            commit("SET_LOADER", false);
        }
    },

    async updateSlide({ commit }, { payload, onSuccess }) {
        try {
            commit("SET_LOADER", true);
            const firestore = firebase.firestore();
            const slideCollection = firestore.collection("slides");
            if (payload?.slideImage.name) {
                let slideInfo = await slideCollection.doc(payload.id).get();
                if (slideInfo.data()) {
                    if (slideInfo.data()?.slideImageUrl != "") {
                        await firebase
                            .storage()
                            .refFromURL(slideInfo?.data()?.slideImageUrl)
                            .delete();
                    }
                }
                const imageFile = payload.slideImage;
                const fileName = imageFile.name;
                const fileExtension = fileName.slice(fileName.lastIndexOf("."));
                const fileNameWithExtension =
                    uuidv4() + fileExtension.toLowerCase();
                const storageRef = firebase
                    .storage()
                    .ref("slide_images/" + fileNameWithExtension);

                try {
                    const uploadTaskSnapshot = await storageRef.put(imageFile);
                    const downloadURL =
                        await uploadTaskSnapshot.ref.getDownloadURL();
                    await slideCollection.doc(payload.id).update({
                        slideImageUrl: downloadURL,

                        slideName: payload.slideName,

                        duration: payload.duration,
                        order: payload.order,
                        updatedAt: firebase.firestore.Timestamp.now(),
                    });
                    alert("Slide updated Successfully");
                    onSuccess();
                    commit("SET_LOADER", false);
                } catch (error) {
                    console.error("Error uploading image:", error);
                    commit("SET_LOADER", false);
                }
            } else {
                if (payload.slideImage == "") {
                    let slideInfo = await slideCollection.doc(payload.id).get();
                    if (slideInfo.data()) {
                        if (slideInfo.data()?.slideImageUrl != "") {
                            await firebase
                                .storage()
                                .refFromURL(slideInfo?.data()?.slideImageUrl)
                                .delete();
                        }
                    }
                }
                await slideCollection.doc(payload.id).update({
                    updatedAt: firebase.firestore.Timestamp.now(),
                    slideImageUrl: payload.slideImage,
                    slideName: payload.slideName,

                    duration: payload.duration,
                    order: payload.order,
                });
                alert("Slide updated Successfully");
                onSuccess();
                commit("SET_LOADER", false);
            }
        } catch (error) {
            alert(error);
            commit("SET_LOADER", false);

            console.error(error);
        }
    },

    // GETTING ALL SLIDES
    async getSlides({ commit }, id) {
        try {
            const slidesRef = firebase.firestore().collection("slides");

            const query = slidesRef
                .where("userId", "==", id)
                .orderBy("order", "asc");
            query.onSnapshot((querySnapshot) => {
                const slides = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    slides.push({ id: doc.id, ...data });
                });

                commit("SET_SLIDES_DATA", slides);
            });
        } catch (error) {
            console.error("Error fetching slides:", error);
        }
    },

    //DELETE USER
    async deleteSlide({ commit }, { id, imageUrl }) {
        console.log("id, imageUrl : ", id, `slide_images/${imageUrl}`);

        try {
            await firebase.firestore().collection("slides").doc(id).delete();
            await firebase.storage().refFromURL(imageUrl).delete();

            console.log("Slide deleted successfully");
        } catch (error) {
            console.error("Error deleting slide:", error);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
