<template>
    <BRow class="d-flex justify-content-center align-items-center login-page">
        <BCol class="col-md-8 col-xl-4">
            <BCard class="settings__inner py-3">
                <BCardBody class="text-center">
                    <div
                        class="d-flex justify-content-center align-items-center"
                    >
                        <img class="h-50 w-50 mx-2" :src="logo" alt="" />
                    </div>
                    <form @submit.prevent="handleLogin">
                        <BFormGroup class="my-2 inner__form">
                            <div
                                class="d-flex flex-column justify-content-start align-items-start"
                            >
                                <label
                                    class="text-uppercase inner__form-label mt-3 mb-2"
                                    >Username</label
                                >
                                <div class="position-relative w-100">
                                    <BFormInput
                                        required
                                        type="username"
                                        placeholder="Username"
                                        class="inner__form-input"
                                        v-model="username"
                                    ></BFormInput>
                                </div>
                            </div>
                        </BFormGroup>
                        <BFormGroup class="my-2 inner__form">
                            <BButton
                                :disabled="this.loader"
                                class="w-100 inner__form-btn py-3"
                                type="submit"
                            >
                                {{
                                    this.loader ? "Loading..." : "Continue"
                                }}</BButton
                            >
                        </BFormGroup>
                    </form>
                </BCardBody>
            </BCard></BCol
        >
    </BRow>
</template>

<script>
import logo from "@/assets/imgs/logo.png";
export default {
    data() {
        return {
            logo,
            username: "",
        };
    },
    methods: {
        handleLogin() {
            this.userNameLogin({
                username: this.username,
                onSuccess: () => {
                    console.log("this?.userName?.id", this?.userName?.id);
                    if (this?.userName?.id) {
                        this.$router.push(`/screen/${this?.userName?.id}`);
                    } else {
                        alert("invalid Username");
                        this.username = "";
                    }
                },
            });
        },
    },
};
</script>

<style></style>
