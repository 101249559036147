<template>
    <BRow class="d-flex justify-content-center align-items-center login-page">
        <BCol class="col-xs-10 col-sm-8 col-md-7 col-lg-5 col-xl-4">
            <BCard class="settings__inner py-3">
                <BCardBody class="text-center">
                    <div
                        class="d-flex justify-content-center align-items-center"
                    >
                        <img class="h-50 w-50 mx-2" :src="logo" alt="" />
                    </div>

                    <form @submit.prevent="handleLogin">
                        <BFormGroup class="my-2 inner__form">
                            <div
                                class="d-flex flex-column justify-content-start align-items-start"
                            >
                                <label
                                    class="text-uppercase inner__form-label mt-3 mb-2"
                                    >Username</label
                                >
                                <div class="position-relative w-100">
                                    <!-- <img
                                    src="../assets//svgs/eye.svg"
                                    alt=""
                                    class="inner__form-eye_icon"
                                /> -->

                                    <BFormInput
                                        required
                                        type="email"
                                        placeholder="Unique ID"
                                        class="inner__form-input"
                                        v-model="email"
                                    ></BFormInput>
                                </div>
                            </div>
                        </BFormGroup>
                        <BFormGroup class="my-2 inner__form">
                            <div
                                class="d-flex flex-column justify-content-start align-items-start"
                            >
                                <label
                                    class="text-uppercase inner__form-label mt-3 mb-2"
                                    >Enter password here</label
                                >
                                <div class="position-relative w-100">
                                    <img
                                        src="../assets//svgs/eye.svg"
                                        alt=""
                                        class="inner__form-eye_icon"
                                        @click="showPassword = !showPassword"
                                    />

                                    <BFormInput
                                        required
                                        :type="
                                            showPassword ? 'text' : 'password'
                                        "
                                        placeholder="Password"
                                        class="inner__form-input"
                                        v-model="password"
                                    ></BFormInput>
                                </div>
                            </div>
                            <BButton
                                :disabled="this.loader"
                                class="w-100 inner__form-btn py-3"
                                type="submit"
                            >
                                {{
                                    this.loader ? "Loading..." : "Connect"
                                }}</BButton
                            >
                        </BFormGroup>
                    </form>
                    <router-link
                        to="/forget-password"
                        class="text-decoration-none cursor-pointer"
                    >
                        <div class="d-flex justify-content-end">
                            <label
                                class="text-uppercase inner__form-label mt-3 cursor-pointer"
                                >Forget Password ?</label
                            >
                        </div>
                    </router-link>
                </BCardBody>
            </BCard></BCol
        >
    </BRow>
</template>

<script>
import logo from "@/assets/imgs/logo.png";
export default {
    data() {
        return {
            email: "",
            password: "",
            logo,
            showPassword: false,
        };
    },
    methods: {
        handleLogin() {
            this.UserLogin({
                email: this.email,
                password: this.password,
                onSuccess: () => {
                    if (this.userData?.role === "customer") {
                        this.$router.push(`/slides/${this.userData?.docId}`);
                    } else {
                        this.$router.push("/profile");
                    }
                },
            });
        },
    },
};
</script>

<style></style>
