<template>
    <div v-if="image" key="currentImage">
        <img :src="image" alt="Image" class="img-fluid img-content" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            paramId: "",
            usersScreenData: [],
            currentImageIndex: 0,
            image: null,
            duration: 0,
            timer: null,
        };
    },
    created() {
        this.paramId = this.$route.params.userid;
        if (this.paramId) {
            this.getScreenUser({ userId: this.paramId });
        }
    },
    watch: {
        usersScreen() {
            const data = [...this.usersScreen];
            this.usersScreenData = data;

            this.displayImageWithDuration();
        },
        image() {
            console.log("duration of current slidee", this.duration);
        },
        // usersScreenData() {
        //     this.displayImageWithDuration();
        // },
    },
    methods: {
        displayImageWithDuration() {
            this.image =
                this.usersScreenData[this.currentImageIndex]?.slideImageUrl;
            // console.log("image: ", this.image);
            this.duration =
                this.usersScreenData[this.currentImageIndex]?.duration;
            // console.log("duration of current slidee", this.duration);
            if (this.timer !== null) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
                this.currentImageIndex =
                    (this.currentImageIndex + 1) % this.usersScreenData.length;
                this.displayImageWithDuration();
            }, this.duration * 1000);
        },
    },
};
</script>

<style lang="scss" scoped>
.main-screen {
}
.img-content {
    height: 100svh;
    width: 100vw !important;
    object-fit: fill !important;
}
</style>
