import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            myrules: {
                required: (value) => !!value || "Required.",
            },
        };
    },

    computed: {
        ...mapGetters("HonourUser", ["userData"]),
        ...mapGetters("HonourUser", ["loader"]),
        ...mapGetters("Slides", ["addSlideloader"]),
        ...mapGetters("Slides", ["slidesData"]),
        ...mapGetters("HonourUser", ["usersProfile"]),
        ...mapGetters("HonourUser", ["usersScreen"]),
        ...mapGetters("HonourUser", ["userName"]),
    },

    mounted() {},
    methods: {
        ...mapActions("HonourUser", ["UserLogin"]),
        ...mapActions("HonourUser", ["AddProfileUser"]),
        ...mapActions("HonourUser", ["getProfileUser"]),
        ...mapActions("HonourUser", ["getScreenUser"]),
        ...mapActions("HonourUser", ["deleteProfileUser"]),
        ...mapActions("HonourUser", ["updateProfileUser"]),
        ...mapActions("HonourUser", ["userForgetPassword"]),
        ...mapActions("HonourUser", ["checkUpdateEmail"]),
        ...mapActions("HonourUser", ["checkEmail"]),
        ...mapActions("HonourUser", ["callCreateUser"]),
        ...mapActions("HonourUser", ["userNameLogin"]),
        ...mapActions("HonourUser", ["logout"]),
        ...mapActions("Slides", ["addNewSlide"]),
        ...mapActions("Slides", ["getSlides"]),
        ...mapActions("Slides", ["updateSlide"]),
        ...mapActions("Slides", ["deleteSlide"]),
    },
};
