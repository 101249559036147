<template>
    <BRow>
        <BCol>
            <div class="page-header">
                <div class="page-header__box"></div>
                <div class="page-header__content">Profiles</div>
            </div>
        </BCol>
    </BRow>

    <BRow>
        <AddProfile :updateData="updateData" />
    </BRow>
    <BRow>
        <BCol sm="12">
            <ProfileTable @updateData="handleUpdateData" />
        </BCol>
    </BRow>
</template>

<script>
import ProfileTable from "@/components/profile/ProfileTable.vue";
import AddProfile from "@/components/profile/AddProfile.vue";
export default {
    components: {
        ProfileTable,
        AddProfile,
    },
    data() {
        return {
            updateData: null,
        };
    },
    methods: {
        handleUpdateData(obj) {
            this.updateData = obj;
        },
    },
};
</script>

<style lang="sass" scoped></style>
