<template>
    <BContainer>
        <SlidesMain />
    </BContainer>
</template>

<script>
import SlidesMain from "@/components/slides/SlidesMain.vue";

export default {
    components: {
        SlidesMain,
    },
};
</script>

<style lang="scss" scoped></style>
