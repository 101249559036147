<template>
    <div>
        <component :is="$route.meta.layout">
            <router-view></router-view>
        </component>
    </div>
</template>

<script>
//Honor-sliders
import Footer from "@/components/globals/Footer.vue";

//old code
import "./assets/scss/index.scss";

import MainLayout from "@/layout/Main.vue";

export default {
    components: {
        Footer,
        MainLayout,
    },
};
</script>
<style lang="scss">
.centered-table {
    .table {
        --bs-table-bg: #fcfcfc !important;
    }
    td {
        text-align: center;
        vertical-align: middle;
        width: 40% !important;
    }
    th {
        width: 40% !important;
    }
}
</style>
