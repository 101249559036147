<template>
    <CustomTable
        :items="displayedItems"
        :fields="fields"
        :updatefun="updatefun"
        :deletefun="deletefun"
        :handleScreen="handleScreen"
        :slidefun="slidefun"
    />
    <div>
        <PaginationTable
            :items="items"
            :currentPage="currentPage"
            :totalPages="totalPages"
            :startNumber="startNumber"
            :endNumber="endNumber"
            @page-changed="handlePageChange"
        />
    </div>
</template>

<script>
import gImg1 from "../../assets/imgs/gImg1.png";
import gImg2 from "../../assets/imgs/gImg2.png";
import gImg3 from "../../assets/imgs/gImg3.png";
import deleteIcon from "../../assets/imgs/delete.png";
import editIcon from "../../assets/imgs/edit.png";
import CustomTable from "../../components/globals/CustomTable.vue";
import PaginationTable from "../../components/globals/PaginationTable.vue";

export default {
    components: {
        CustomTable,
        PaginationTable,
    },
    props: {
        updateData: Object,
    },
    data() {
        gImg1;
        return {
            perPage: 10,
            currentPage: 1,
            startNumber: 1,
            endNumber: 1,
            fields: [
                { key: "logo", label: "Logo/Crest" },
                { key: "organization", label: "Organisation" },
                { key: "email", label: "Email" },
                { key: "Action", label: "Action" },
            ],
            items: [],
        };
    },
    watch: {
        usersProfile() {
            const usersProfileData = [];

            console.log("this.usersProfile", this.usersProfile);
            for (const userobj of this.usersProfile) {
                usersProfileData.push({
                    logo: {
                        url: userobj?.profileLogoUrl,
                    },
                    organization: userobj?.organization,
                    email: userobj?.email,
                    password: "password",
                    id: userobj?.docId,
                    Action: {
                        deleteUrl: deleteIcon,
                        editUrl: editIcon,
                        screen: "View Screen",
                        slide: "Add Slide",
                    },
                });
            }
            this.items = usersProfileData;
        },
    },
    computed: {
        totalPages() {
            return Math.ceil(this.items.length / this.perPage);
        },
        displayedItems() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;
            this.startNumber = startIndex;
            this.endNumber = endIndex;
            return this.items.slice(startIndex, endIndex);
        },
    },

    mounted() {
        console.log("this.usersProfile", this.usersProfile);
    },
    methods: {
        handlePageChange(page) {
            this.currentPage = page;
        },
        updatefun(obj) {
            window.scrollTo(0, 0);
            this.$emit("updateData", obj);
        },
        slidefun(obj) {
            this.$router.push({
                path: `/slides/${obj.id}`,
            });
        },
        deletefun(obj) {
            this.deleteProfileUser({ id: obj.id, imageUrl: obj.logo.url });
        },
        handleScreen(obj) {
            if (obj.id) {
                const newTab = window.open(`/screen/${obj.id}`, "_blank");
                if (newTab) {
                    newTab.focus();
                } else {
                    alert(
                        "The pop-up was blocked. Please allow pop-ups for this site."
                    );
                }
            }
        },
    },
    created() {
        this.getProfileUser({
            createdBy: this.userData.docId,
        });
    },
};
</script>

<style lang="scss" scoped></style>
