<template>
    <BRow>
        <BCol sm="12">
            <div class="custom-table">
                <b-table
                    responsive
                    :fields="fields"
                    :items="items"
                    class="centered-table mb-0"
                >
                    <template #cell(logo)="data">
                        <div class="d-flex align-items-center">
                            <div class="custom-table__checkbox">
                                <b-form-checkbox></b-form-checkbox>
                            </div>
                            <div>
                                <img
                                    :src="data.value?.url || AvatarSlider"
                                    class="custom-table__logo"
                                />
                            </div>
                            <!-- <div class="custom-table__img-text ps-2">
                                {{ data.value?.name }}
                            </div> -->
                        </div>
                    </template>
                    <template #cell(Image)="data">
                        <div class="d-flex align-items-center">
                            <div class="custom-table__checkbox">
                                <b-form-checkbox></b-form-checkbox>
                            </div>
                            <div>
                                <img
                                    :src="data.value?.url || AvatarSlider"
                                    class="custom-table__img"
                                />
                            </div>
                            <div class="custom-table__img-text ps-2">
                                {{ data.value?.name }}
                            </div>
                        </div>
                    </template>
                    <template #cell()="data">
                        <div class="custom-table__text">
                            {{ data?.value }}
                        </div>
                    </template>
                    <template #cell(organization)="data">
                        <div class="custom-table__organisation-text">
                            {{ data?.value }}
                        </div>
                    </template>
                    <template #cell(Action)="data">
                        <div class="custom-table__action">
                            <div
                                class="custom-table__action__button"
                                @click="deletefun(data?.item)"
                            >
                                <img :src="data?.value?.deleteUrl" />
                            </div>
                            <div
                                class="custom-table__action__button"
                                @click="updatefun(data?.item)"
                            >
                                <img :src="data?.value?.editUrl" />
                            </div>
                            <div
                                v-if="data.value?.slide"
                                class="custom-table__action__buttonScreen"
                                @click="slidefun(data?.item)"
                            >
                                {{ data?.value?.slide }}
                            </div>
                            <div
                                v-if="data?.value?.screen"
                                class="custom-table__action__buttonScreen"
                                @click="handleScreen(data?.item)"
                            >
                                {{ data?.value?.screen }}
                            </div>
                        </div>
                    </template>

                    <template #head(logo)="data">
                        <div class="d-flex align-items-center">
                            <div class="custom-table__checkbox">
                                <b-form-checkbox
                                    v-model="selectAll"
                                    @change="toggleAllSelection"
                                ></b-form-checkbox>
                            </div>
                            <div class="custom-table__headerText">
                                {{ data?.label }}
                            </div>
                        </div>
                    </template>
                    <template #head(Image)="data">
                        <div class="d-flex align-items-center">
                            <div class="custom-table__checkbox">
                                <b-form-checkbox
                                    v-model="selectAll"
                                    @change="toggleAllSelection"
                                ></b-form-checkbox>
                            </div>
                            <div class="custom-table__headerText">
                                {{ data?.label }}
                            </div>
                        </div>
                    </template>

                    <template #head(Action)="data">
                        <div class="custom-table__headerAction">
                            {{ data?.label }}
                        </div>
                    </template>
                    <template #head()="data">
                        <div class="custom-table__headerText">
                            {{ data?.label }}
                        </div>
                    </template>
                </b-table>
            </div>
        </BCol>
    </BRow>
</template>

<script>
import AvatarSlider from "@/assets/imgs/AvatarSlider.png";
export default {
    props: {
        fields: Array,
        items: Array,
        updatefun: Function,
        deletefun: Function,
        handleScreen: Function,
        slidefun: Function,
    },
    data() {
        return {
            AvatarSlider,
        };
    },
};
</script>

<style lang="scss" scoped></style>
