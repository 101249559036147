<template>
    <form @submit.prevent="id ? handleUpdateProfile() : handleAddProfile()">
        <BRow>
            <!-- <BCol md="3" class="mb-2">
                <div>
                    <div class="input-label mb-2 d-flex align-items-center">
                        <span>Logo / Crest</span>
                    </div>
                    <div>
                        <BFormInput
                            v-model="crest"
                            type="text"
                            class="input-field"
                            required
                        ></BFormInput>
                    </div>
                </div>
            </BCol> -->
            <BCol md="4" class="mb-2">
                <div>
                    <div class="input-label mb-2 d-flex align-items-center">
                        <span>Club organizations</span>
                    </div>
                    <div>
                        <BFormInput
                            v-model="organization"
                            required
                            type="text"
                            class="input-field"
                        ></BFormInput>
                    </div>
                </div>
            </BCol>

            <BCol md="4" class="mb-2">
                <div>
                    <div class="input-label mb-2 d-flex align-items-center">
                        <span>Email</span>
                    </div>
                    <div>
                        <BFormInput
                            v-model="email"
                            required
                            type="email"
                            class="input-field"
                            v-if="id"
                            readonly
                        ></BFormInput>
                        <BFormInput
                            v-model="email"
                            required
                            type="email"
                            class="input-field"
                            v-else
                        ></BFormInput>
                    </div>
                </div>
            </BCol>
            <BCol md="4" class="mb-2">
                <div>
                    <div class="input-label mb-2 d-flex align-items-center">
                        <span>Password</span>
                    </div>
                    <div>
                        <BFormInput
                            v-if="id"
                            v-model="password"
                            required
                            type="password"
                            class="input-field"
                            readonly=""
                        ></BFormInput>
                        <BFormInput
                            v-else
                            v-model="password"
                            required
                            type="password"
                            class="input-field"
                        ></BFormInput>
                    </div>
                </div>
            </BCol>
            <BCol lg="8" md="10" class="mb-2">
                <div>
                    <div class="input-label d-flex align-items-center mb-2">
                        <span>Logo / Crest</span>
                    </div>
                    <BRow class="justify-content-between">
                        <BCol md="2" class="my-md-0 my-1">
                            <div>
                                <img
                                    class="file-img"
                                    :src="profilePreview || AvatarSlider"
                                />
                            </div>
                        </BCol>
                        <BCol md="6" class="my-md-0 my-1">
                            <div
                                @click="openFileDialog"
                                class="input-file d-flex justify-content-center align-items-center cursor-pointer"
                            >
                                <div class="mx-2">
                                    <img :src="file" />
                                </div>
                                <div class="input-file__text">
                                    <span>Upload new logo/crest</span>
                                </div>
                            </div>
                        </BCol>
                        <BCol md="4" class="my-md-0 my-1">
                            <div
                                class="delete-current text-center"
                                @click="deleteCurrentImage"
                            >
                                Delete current
                            </div>
                        </BCol>

                        <input
                            ref="fileInput"
                            type="file"
                            accept="image/*"
                            style="display: none"
                            @change="handleFileChange"
                        />
                    </BRow>
                </div>
            </BCol>
        </BRow>

        <div class="d-flex justify-content-end mb-2">
            <BButton
                v-if="!id"
                :disabled="loader"
                class="profile__btn cursor-pointer mr-2"
                type="submit"
            >
                <template v-if="loader">...loading</template>
                <template v-else>
                    <svg
                        v-if="!id"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4Z"
                            fill="white"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13 7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H11V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V13H17C17.2652 13 17.5196 12.8946 17.7071 12.7071C17.8946 12.5196 18 12.2652 18 12C18 11.7348 17.8946 11.4804 17.7071 11.2929C17.5196 11.1054 17.2652 11 17 11H13V7Z"
                            fill="white"
                        />
                    </svg>
                    Add New User
                </template>
            </BButton>
            <BButton
                v-if="id"
                :disabled="loader"
                class="profile__btn cursor-pointer mx-3"
                type="submit"
            >
                <template v-if="loader">...loading</template>
                <template v-else>
                    <svg
                        v-if="!id"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4Z"
                            fill="white"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13 7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H11V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V13H17C17.2652 13 17.5196 12.8946 17.7071 12.7071C17.8946 12.5196 18 12.2652 18 12C18 11.7348 17.8946 11.4804 17.7071 11.2929C17.5196 11.1054 17.2652 11 17 11H13V7Z"
                            fill="white"
                        />
                    </svg>
                    Update User
                </template>
            </BButton>

            <BButton
                v-if="id"
                class="profile__btn cursor-pointer"
                @click="cancelUpdate"
            >
                Cancel
            </BButton>
        </div>
    </form>
</template>

<script>
import subtract from "../../assets/imgs/Subtract.png";
import file from "../../assets/imgs/file.png";

import AvatarSlider from "@/assets/imgs/AvatarSlider.png";
export default {
    data() {
        return {
            password: "",
            organization: "",
            email: "",
            id: "",
            fileInput: null,
            subtract,
            file,
            AvatarSlider,
            profilePreview: null,
            profileImage: "",
        };
    },
    props: {
        updateData: Object,
    },
    methods: {
        handleAddProfile() {
            this.checkEmail({
                email: this.email.toLowerCase(),
                onSuccess: (data) => {
                    if (!data) {
                        this.callCreateUser({
                            email: this.email,
                            password: this.password,
                        })
                            .then((res) => {
                                console.log("res: ", res);
                                const payload = {
                                    organization: this.organization,
                                    email: this.email.toLowerCase(),
                                    profileImage: this.profileImage,
                                    createdBy: this.userData.docId,
                                    uid: res.data.uid,
                                };
                                this.AddProfileUser({
                                    payload: payload,
                                    onSuccess: () => {
                                        this.password = "";
                                        this.organization = "";
                                        this.email = "";
                                        this.profilePreview = null;
                                        this.profileImage = "";
                                    },
                                });
                            })
                            .catch((error) => {
                                console.error(error);

                                alert("Error creating user");
                            });
                    } else {
                        alert("Username already exists");
                    }
                },
            });
        },
        handleUpdateProfile() {
            this.checkUpdateEmail({
                createdBy: this.id,
                email: this.email,

                onSuccess: (data) => {
                    if (data) {
                        const payload = {
                            // password: this.password,
                            organization: this.organization,
                            profileImage: this.profileImage,
                            email: this.email,
                            id: this.id,
                        };
                        this.updateProfileUser({
                            payload: payload,
                            onSuccess: () => {
                                this.password = "";
                                this.organization = "";
                                this.email = "";
                                this.profileImage = "";
                                this.profilePreview = null;
                                this.profileImage = "";
                                this.id = "";
                            },
                        });
                    } else {
                        alert("Username  already  exist");
                    }
                },
            });
        },
        cancelUpdate() {
            this.id = null;
            this.password = "";
            this.organization = "";
            this.email = "";
            this.profilePreview = null;
            this.profileImage = "";
        },
        openFileDialog() {
            this.$refs.fileInput.click();
        },
        handleFileChange(event) {
            const selectedFile = event.target.files[0];

            const allowedTypes = [
                "image/png",
                "image/jpeg",
                "image/jpg",
                "image/svg+xml",
            ];
            if (allowedTypes.includes(selectedFile?.type)) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.profilePreview = reader.result;
                    this.profileImage = selectedFile;
                    this.$refs.fileInput.value = "";
                };
                reader.readAsDataURL(selectedFile);
            } else {
                alert("Invalid File Select Image");
            }
        },
        deleteCurrentImage() {
            this.profilePreview = null;
            this.profileImage = "";
        },
    },
    watch: {
        updateData() {
            if (this.updateData) {
                console.log("this.updateData: ", this.updateData);
                this.password = this.updateData.password;
                this.organization = this.updateData.organization;
                this.email = this.updateData.email;
                this.id = this.updateData.id;
                this.profilePreview = this.updateData.logo.url;
                this.profileImage = this.updateData.logo.url;
            }
        },
    },
    mounted() {
        this.fileInput = this.$refs.fileInput;
    },
};
</script>

<style></style>
