import App from "./App.vue";
import Vue from "vue";

import { createApp } from "vue";
import BootstrapVueNext from "bootstrap-vue-next";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import router from "./router";
import store from "./store";
import global from "./mixins/global";

const app = createApp(App);
app.mixin(global);
app.use(router);
app.use(store);
app.use(BootstrapVueNext);
app.mount("#app");
