<template>
    <BRow>
        <BCol sm="12">
            <CustomTable
                :items="displayedItems"
                :fields="fields"
                :deletefun="deletefun"
                :updatefun="updatefun"
            />
            <div>
                <PaginationTable
                    :items="items"
                    :currentPage="currentPage"
                    :totalPages="totalPages"
                    :startNumber="startNumber"
                    :endNumber="endNumber"
                    @page-changed="handlePageChange"
                />
            </div>
        </BCol>
    </BRow>
</template>

<script>
import PaginationTable from "../../components/globals/PaginationTable.vue";
import CustomTable from "../../components/globals/CustomTable.vue";

export default {
    props: {
        items: Array,
        updateData: Object,
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            startNumber: 1,
            endNumber: 1,

            fields: [
                { key: "Image", label: "Image" },
                { key: "Duration", label: "Duration" },
                { key: "Order", label: "Order" },
                { key: "Action", label: "Action" },
            ],
        };
    },
    components: {
        PaginationTable,
        CustomTable,
    },
    methods: {
        handlePageChange(page) {
            this.currentPage = page;
        },
        deletefun(obj) {
            this.deleteSlide({ id: obj.id, imageUrl: obj.Image.url });
        },
        updatefun(obj) {
            window.scrollTo(0, 0);
            this.$emit("updateData", obj);
        },
    },
    computed: {
        totalPages() {
            return Math.ceil(this.items.length / this.perPage);
        },
        displayedItems() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;
            this.startNumber = startIndex;
            this.endNumber = endIndex;
            return this.items.slice(startIndex, endIndex);
        },
    },
};
</script>

<style lang="scss" scoped></style>
