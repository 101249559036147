<template>
    <BRow>
        <BCol>
            <div
                class="page-header d-flex justify-content-between align-items-center"
            >
                <div class="d-flex">
                    <div class="page-header__box"></div>
                    <div class="page-header__content">Slides</div>
                </div>

                <div class="d-flex">
                    <div>
                        <BButton
                            v-if="userDetails?.role == 'admin'"
                            class="page-header__button"
                            @click.prevent="handleBack"
                            >Back</BButton
                        >
                    </div>

                    <div class="ms-2">
                        <BButton
                            class="page-header__button"
                            @click.prevent="handleScreen"
                            >View Screen</BButton
                        >
                    </div>
                </div>
            </div>
        </BCol>
    </BRow>
    <BRow>
        <AddSlides :updateData="updateData" :paramId="paramId" />
    </BRow>
    <BRow>
        <BCol sm="12">
            <SlidesTable :items="items" @updateData="handleUpdateData" />
        </BCol>
    </BRow>
    <BRow>
        <BCol>
            <div
                class="page-header my-2 d-flex justify-content-end align-items-center"
            >
                <div>
                    <BButton
                        v-if="userDetails?.role == 'admin'"
                        class="page-header__button"
                        @click.prevent="handleBack"
                        >Back</BButton
                    >
                </div>
            </div>
        </BCol>
    </BRow>
</template>

<script>
import SlidesTable from "@/components/slides/SlidesTable.vue";
import AddSlides from "@/components/slides/AddSlides.vue";
import deleteIcon from "../../assets/imgs/delete.png";
import editIcon from "../../assets/imgs/edit.png";
export default {
    components: {
        SlidesTable,
        AddSlides,
    },

    data() {
        return {
            updateData: null,
            userDetails: {},
            paramId: "",
            items: [],
        };
    },
    methods: {
        handleUpdateData(obj) {
            this.updateData = obj;
        },
        handleBack() {
            this.$router.back();
        },
        handleScreen() {
            // if (this.paramId) {
            //     this.$router.push({
            //         path: `/screen/${this.paramId}`,
            //     });
            // }
            if (this.paramId) {
                const newTab = window.open(`/screen/${this.paramId}`, "_blank");
                if (newTab) {
                    newTab.focus();
                } else {
                    alert(
                        "The pop-up was blocked. Please allow pop-ups for this site."
                    );
                }
            }
        },
    },
    created() {
        this.paramId = this.$route.params.id;
        this.getSlides(this.paramId);

        if (this.userData) {
            const data = this.userData;
            this.userDetails = data;
        }
    },

    watch: {
        slidesData() {
            const slides = [];

            for (const slide of this.slidesData) {
                slides.push({
                    Image: {
                        url: slide?.slideImageUrl,
                        name: slide?.slideName,
                    },
                    Duration: slide?.duration,
                    Order: slide?.order,
                    id: slide?.id,
                    Action: { deleteUrl: deleteIcon, editUrl: editIcon },
                });
            }
            this.items = slides;
            console.log(slides);
        },

        paramId(newId) {
            console.log("new id", newId);
        },
    },

    // mounted() {
    //     this.handleUpdateData(this.updateData);
    //     if (this.updateData) {
    //         this.handleUpdateData(this.updateData);
    //     }
    // },
};
</script>

<style></style>
