import firebase from "firebase/app";

// import firebase from 'firebase';
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyCfc6h0JtfRkTh-ohvznX7_0c4U5hoq7wY",
    authDomain: "club-honours-slides.firebaseapp.com",
    projectId: "club-honours-slides",
    storageBucket: "club-honours-slides.appspot.com",
    messagingSenderId: "141597369125",
    appId: "1:141597369125:web:ab96ebfa27576de85af0cc",
    measurementId: "G-VEGZJ5VGQS",
};
firebase.initializeApp(firebaseConfig);
export default firebase;
