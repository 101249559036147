<template>
    <BContainer>
        <ProfilesMain />
    </BContainer>
</template>

<script>
import ProfilesMain from "@/components/profile/ProfilesMain.vue";

export default {
    components: {
        ProfilesMain,
    },

    created() {
        if (this.userData.role !== "admin") {
            this.$router.push(`slides/${this.userData.docId}`);
        }
    },
};
</script>

<style lang="scss" scoped></style>
